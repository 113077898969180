<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    destroy-on-close
    width="40%"
    v-on="$listeners">
    <base-title slot="title" :text="$t('resetPassword')"></base-title>
    <div>
      <el-steps :active="currentStep" simple>
        <el-step :title="$t('inputUserInfo')" icon="el-icon-edit-outline"></el-step>
        <el-step :title="$t('resetPassword')" icon="el-icon-key"></el-step>
      </el-steps>
      <div style="padding: 20px">
        <el-form
          class="el-form-item-left-lable"
          v-show="currentStep===1"
          ref="firstStepForm"
          :model="firstStepFormData"
          :rules="firstStepRules"
          :label-width="$i18n.locale==='cn'?'80px':'140px'">
          <el-form-item
            :label="$t('email')"
            prop="email">
            <div class="double-column">
              <el-input
                v-model="firstStepFormData.email">
              </el-input>
              <el-button
                style="margin-left: 10px"
                :loading="emailCodeLoading"
                :disabled="emailCodeCountDown>0"
                @click="getCode">
                {{ $t('getCode') }}
                <span v-show="emailCodeCountDown>0">
                  {{ `(${emailCodeCountDown})` }}
                </span>
              </el-button>
            </div>
          </el-form-item>
          <el-form-item :label="$t('verificationCode')" prop="verifyCode">
            <el-input v-model="firstStepFormData.verifyCode"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              style="float: right;width: 120px"
              type="primary"
              round
              @click="next">
              {{ $t('next') }}
            </el-button>
            <el-button
              style="margin-right: 20px;float: right;width: 120px"
              type="danger"
              round
              @click="visible = false">
              {{ $t('cancel') }}
            </el-button>
          </el-form-item>
        </el-form>
        <el-form
          v-show="currentStep===2"
          class="el-form-item-left-lable"
          ref="secondStepForm"
          :model="secondStepFormData"
          :rules="secondStepRules"
          :label-width="$i18n.locale==='cn'?'80px':'160px'">
          <el-form-item :label="$t('password')" prop="password">
            <el-input v-model="secondStepFormData.password" show-password></el-input>
          </el-form-item>
          <el-form-item :label="$t('duplicatePassword')" prop="duplicatePassword">
            <el-input v-model="secondStepFormData.duplicatePassword" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              style="float: right"
              type="primary"
              round
              @click="submitResetPassword">
              {{ $t('submit') }}
            </el-button>
            <el-button
              style="margin-right: 20px;float: right"
              type="danger"
              round
              @click="visible = false">
              {{ $t('cancel') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'

export default {
  name: 'ResetPassword',
  mixins: [baseMixin],
  data () {
    const validateDuplicatePassword = (rule, value, callback) => {
      if (value !== this.secondStepFormData.password) {
        return callback(new Error(this.$t('pswNotMatch')))
      }
      return callback()
    }
    return {
      visible: false,
      currentStep: 1,
      firstStepFormData: {
        email: '',
        verifyCode: '',
        verifyCodeId: ''
      },
      firstStepRules: {
        email: [
          {
            required: true,
            message: this.$t('rejectBeEmpty'),
            trigger: 'blur'
          },
          {
            type: 'email',
            message: this.$t('emailFormatError'),
            trigger: 'blur'
          }
        ],
        verifyCode: [
          {
            required: true,
            message: this.$t('rejectBeEmpty'),
            trigger: 'blur'
          }
        ]
      },
      secondStepFormData: {
        password: '',
        duplicatePassword: ''
      },
      secondStepRules: {
        password: [
          {
            required: true,
            message: this.$t('rejectBeEmpty'),
            trigger: 'blur'
          },
          {
            pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(-)_+=~`]).{8,30})/,
            message: 'Password at least one number, uppercase, lowercase, special characters, 8 to 30 bits in length',
            trigger: 'blur'
          }
        ],
        duplicatePassword: [
          {
            required: true,
            message: this.$t('rejectBeEmpty'),
            trigger: 'blur'
          },
          {
            validator: validateDuplicatePassword,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    this.visible = true
  },
  methods: {
    getCode () {
      /**
       * 获取验证码
       */
      this.$refs.firstStepForm.validateField('email', err => {
        if (!err) {
          this.getEmailCodeInternal(this.firstStepFormData.email).then(codeId => {
            this.firstStepFormData.verifyCodeId = codeId
          })
        }
      })
    },

    next () {
      /**
       * 进入下一步
       */
      this.$refs.firstStepForm.validate(valid => {
        if (valid) {
          this.currentStep = 2
        }
      })
    },

    submitResetPassword () {
      /**
       * 提交修改密码
       */
      this.$refs.secondStepForm.validate(valid => {
        if (valid) {
          this.$request.post({
            url: this.$apis.resetPassword,
            data: {
              ...this.firstStepFormData,
              password: this.secondStepFormData.password
            }
          }).then(data => {
            if (data?.code === 1000) {
              this.$message.success(this.$t('passwordResetSuccessful'))
              this.visible = false
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.double-column {
  @include flex-center-row;
}
</style>
